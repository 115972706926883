import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import LinkedInMonogram from "./linkedin-monogram.js"
import MediumMonogram from "./medium-monogram.js"
import TwitterMonogram from "./twitter-monogram.js"
import GitHubMonogram from "./github-monogram.js"

const Header = ({ siteTitle }) => {
  const {
    site: {
      siteMetadata: {
        author
      }
    }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author {
              name
              bio
            }
          }
        }
      }
    `
  )

  return (
    <header className="mb-8">

      <div className="flex flex-wrap mb-1">
        <h1 className="flex-auto text-5xl leading-none mb-3">
          {author.name}
        </h1>

        <div className="flex-initial order-1 md:order-none md:mb-0 md:self-center md:mr-3">
          <ul className="flex-initial mr-2 flex print:hidden">
            <li>
              <OutboundLink className="-ml-2 px-2" href="https://linkedin.com/in/akagomez" target="_blank">
                <LinkedInMonogram className="inline-block " width="20px" height="20px" rectFill="#ccc" />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink className="px-2" href="https://github.com/akagomez" target="_blank">
                <GitHubMonogram className="inline-block " width="20px" height="20px" rectFill="#ccc" />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink className="px-2" href="https://twitter.com/akxgomez" target="_blank">
                <TwitterMonogram className="inline-block " width="20px" height="20px" rectFill="#ccc" />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink className="px-2" href="https://medium.com/@akagomez" target="_blank">
                <MediumMonogram className="inline-block " width="20px" height="20px" rectFill="#ccc" />
              </OutboundLink>
            </li>
          </ul>
        </div>

        {/**
        <div className="flex-auto flex items-end justify-between order-1 text-gray-600 md:order-none md:mb-0 md:self-center">
          <p className="text-left md:text-center">
            <span className="block text-lg leading-none">
              100+
            </span>
            <span className="block" style={{ fontSize: "0.5rem" }}>
              COMPANIES
            </span>
          </p>
          <p className="text-left md:text-center">
            <span className="block text-lg leading-none">
              150+
            </span>
            <span className="block" style={{ fontSize: "0.5rem" }}>
              PROJECTS
            </span>
          </p>
          <p className="text-left md:text-center">
            <span className="block text-lg leading-none">
              300+
            </span>
            <span className="block" style={{ fontSize: "0.5rem" }}>
              TEAMMATES
            </span>
          </p>
        </div>
        **/}

        <p className="flex-auto w-full mb-2 text-2xl">
          {author.bio}
        </p>

        <nav className="mb-3 flex-auto flex flex-wrap justify-between items-center md:mb-0">
          <div className="flex-1 text-sm text-gray-500">
            <span className="hidden print:inline after:bullet-delimiter">
              <a href="https://meetchrisgomez.com?utm_medium=pdf">
                MeetChrisGomez.com
              </a>
            </span> {' '}
            <span className="whitespace-no-wrap before:contact-location after:bullet-delimiter" /> {' '}
            <span className="whitespace-no-wrap before:contact-email after:bullet-delimiter" /> {' '}
            <span className="whitespace-no-wrap before:contact-phone after:bullet-delimiter" /> {' '}
          </div>
        </nav>

      </div>

    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
