/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  const {
    site: {
      siteMetadata: {
        author,
      }
    }
  } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          author {
            name
          }
        }
      }
    }
  `)

  return (
    <div className="container mx-auto p-5">
      <Header />
      <div>
        <main className="pb-10">
          {children}
        </main>
        <footer className="text-sm text-center print:hidden">
          © {new Date().getFullYear()} {author.name}; Built with{` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>{` `}
          and <a href="https://tailwindcss.com">Tailwind</a>;{` `}
          Hosted by <a href="https://zeit.co/now">Now</a>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
